import React, { Component } from "react";
import MobileUI from "./mobile-ui";
import ClassNames from "classnames";

const arrowKeys = {
  ArrowLeft: "left",
  ArrowUp: "up",
  ArrowRight: "right",
  ArrowDown: "down"
};

class Game extends Component {
  constructor() {
    super();
    this.state = { playing: false, hasStarted: false };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.move = this.move.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.tick = this.tick.bind(this);
    this.reset = this.reset.bind(this);
    this.changeSpeed = this.changeSpeed.bind(this);
    this.handleMobileDirectionChange = this.handleMobileDirectionChange.bind(
      this
    );
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.body.addEventListener("START_GAME", this.start);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  componentWillUpdate(nextProps) {
    // console.log("componentWillUpdate", nextProps);
    if (this.state.playing && !nextProps.alive) {
      this.stop();
    }

    if (
      !this.state.playing &&
      nextProps.startedFromMobile &&
      !this.state.hasStarted
    ) {
      // console.log("start now,right?");
      // this.start();
      this.start();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.points < this.props.points) {
      this.props.nextPost();
    }
  }

  start() {
    if (this.start.playing) {
      return;
    }
    this.setState(
      {
        playing: true
      },
      () => this.tick()
    );
  }

  stop() {
    this.setState({
      playing: false
    });
  }

  tick() {
    if (this.state.playing) {
      this.props.move();
      // Speed is 1-10, map to milliseconds
      setTimeout(this.tick, (11 - this.props.speed) * 40);
    }
  }

  move() {
    this.props.move();
  }

  reset() {
    this.props.reset([16, 16]);
  }

  changeSpeed(change) {
    this.props.setSpeed(Math.min(Math.max(this.props.speed + change, 1), 10));
  }

  handleMobileDirectionChange(direction) {
    if (!this.state.playing) {
      this.start();
    }
    console.log("handleMobileDirectionChange", direction);
    this.props.setDirection(direction);
  }

  handleKeyDown(event) {
    if (arrowKeys[event.key]) {
      console.log(arrowKeys[event.key]);
      this.props.setDirection(arrowKeys[event.key]);
      return;
    }
    const keyMap = {
      " ": () => (this.state.playing ? this.stop() : this.start()),
      r: () => this.reset(),
      f: () => this.move(),
      "+": () => this.changeSpeed(+1),
      "-": () => this.changeSpeed(-1)
    };
    keyMap[event.key] && keyMap[event.key]();
  }

  renderSnake(body, unit) {
    let dirs = [...this.props.tile_directions];
    dirs = dirs.reverse();

    unit = 6.25;
    return body.map((segment, index) => {
      const segmentStyle = {
        left: `${segment[0] * unit}%`,
        bottom: `${segment[1] * unit}%`
        // width: `${unit}px`,
        // height: `${unit}px`
      };

      let classes = "snake-segment";
      let dirOfSibling = dirs[index - 1];
      let dir = dirs[index];
      if (index === 0) {
        classes += ` ${dir} head`;
      } else if (index === dirs.length - 1) {
        if (dirOfSibling !== dir) {
          classes += ` ${dirOfSibling} end`;
        } else {
          classes += ` ${dir} end`;
        }
      } else {
        if (dirOfSibling !== dir) {
          classes += ` corner ${dir}-${dirOfSibling}`;
          classes += ` type-${Math.ceil(Math.random() * 4)}`;
        } else {
          classes += ` body ${dir}`;
        }
      }

      return <div key={index} className={classes} style={segmentStyle} />;
    });
  }

  renderPill(boundary, unit) {
    unit = 6.25;
    const pillStyle = {
      left: `${boundary[0] * unit}%`,
      bottom: `${boundary[1] * unit}%`
      // width: `${(boundary[2] - boundary[0] + 1) * unit}px`,
      // height: `${(boundary[3] - boundary[1] + 1) * unit}px`
    };

    return (
      <div className="pill" style={pillStyle}>
        <div className="border" />
      </div>
    );
  }

  // <div className="controls">
  //   {this.state.playing ? (
  //     <button onClick={this.stop}>◼</button>
  //   ) : (
  //     <button onClick={this.start}>▶</button>
  //   )}
  //   <button onClick={this.move}>▶▶</button>
  //   <button onClick={this.reset}>⏏</button>
  //   <br />
  //   Moves {this.props.moves} (f) , Points {this.props.points}, Speed{" "}
  //   {this.props.speed} (+/-) , Direction {this.props.direction}
  // </div>

  render() {
    const unit = 10;
    const gameStyle = {
      width: `${unit * this.props.dimensions[0]}px`,
      height: `${unit * this.props.dimensions[1]}px`
    };

    return (
      <div
        autoFocus={true}
        tabIndex="0"
        className={ClassNames({
          Snake: true,
          "is-ded": !this.props.alive,
          ["points-" + this.props.points]: true
        })}
      >
        <div className="game-container">
          <div className="game" style={gameStyle}>
            {this.renderSnake(this.props.body, unit)}
            {this.renderPill(this.props.pill, unit)}
          </div>
        </div>
        {this.props.playing && (
          <MobileUI onChange={this.handleMobileDirectionChange} />
        )}
      </div>
    );
  }
}

export default Game;

import React, { Component } from "react";
import ClassNames from "classnames";

class Slideshow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
    this.tock = this.tock.bind(this);
  }

  componentDidMount() {
    this.tick = setInterval(this.tock, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.tick);
  }

  tock() {
    let next = this.state.activeIndex + 1;
    if (next > this.props.media.length - 1) {
      next = 0;
    }
    this.setState({
      activeIndex: next
    });
  }

  render() {
    return (
      <div className="Slideshow">
        {this.props.media.map((item, i) => {
          return (
            <img
              key={i}
              className={ClassNames({
                "is-active": i === this.state.activeIndex
              })}
              alt={this.props.caption}
              src={item.images.standard_resolution.url}
            />
          );
        })}
      </div>
    );
  }
}

export default Slideshow;

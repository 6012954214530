import React, { Component } from "react";
import { TweenMax, Power4 } from "gsap";
// import axios from "axios";

let els = {};

let UI_STATES = {
  INIT: "UI_STATES.INIT",
  LOADED: "UI_STATES.LOADED",
  ANIM_OUT: "UI_STATES.ANIM_OUT"
};

class Preloader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instagramLoaded: false,
      waitComplete: false,
      ui: UI_STATES.INIT
    };
  }

  componentDidUpdate() {
    if (
      this.state.instagramLoaded &&
      this.state.waitComplete &&
      this.state.ui === UI_STATES.INIT
    ) {
      this.setState({
        ui: UI_STATES.ANIM_OUT
      });
    }

    if (this.state.ui === UI_STATES.ANIM_OUT) {
      TweenMax.to(this.el, 0.4, { top: "100%", ease: Power4.easeIn });
    }
  }

  componentDidMount() {
    this.props.loaded([]);
    this.setState({
      waitComplete: true
    });

    // axios
    //   .get("/.netlify/functions/get-grams-2")
    //   .then(response => {
    //     this.setState({
    //       instagramLoaded: true
    //     });
    //     this.props.loaded(response.data.data);
    //   })
    //   .catch(error => {
    //     console.error(error);
    //     this.props.loaded([]);
    //   });
    //
    // setTimeout(() => {
    //   this.setState({
    //     waitComplete: true
    //   });
    // }, 2000);

    for (var i in els) {
      let item = els[i];
      let el = item.el;
      els[i].rect = el.getBoundingClientRect();
      el.setAttribute("style", "opacity: 0;");
    }

    let items = [
      "E1",
      "E2",
      "E3",
      "Square",
      "L11",
      "L12",
      "L21",
      "L22",
      "I1",
      "I2",
      "I3",
      "O1",
      "O2",
      "O3",
      "O4",
      "T1",
      "T2"
    ];

    items.map((key, i) => {
      let item = els[key];
      TweenMax.to(item.el, 0.4, {
        opacity: 1,
        delay: 0.1 * i,
        ease: Power4.easeOut
      });
      return null;
    });
  }

  render() {
    return (
      <div
        className="Preloader"
        ref={el => {
          this.el = el;
        }}
      >
        <div className="logo">
          <svg
            width="952"
            height="232"
            viewBox="0 0 952 232"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M699.325 20H660.506V212.31H699.325V20Z"
              className="box"
              ref={el => {
                els.O4 = { el: el };
              }}
            />
            <path
              d="M776.962 20H738.144V212.31H776.962V20Z"
              ref={el => {
                els.O2 = { el: el };
              }}
              className="box"
            />
            <path
              d="M776.962 173.848H660.506V212.31H776.962V173.848Z"
              className="box"
              ref={el => {
                els.O3 = { el: el };
              }}
            />
            <path
              d="M776.962 20H660.506V58.462H776.962V20Z"
              className="box"
              ref={el => {
                els.O1 = { el: el };
              }}
            />
            <path
              d="M893.418 20H854.599V212.31H893.418V20Z"
              className="box"
              ref={el => {
                els.T2 = { el: el };
              }}
            />
            <path
              d="M932.236 20H815.781V58.462H932.236V20Z"
              className="box"
              ref={el => {
                els.T1 = { el: el };
              }}
            />
            <path
              d="M582.869 20H544.051V212.31H582.869V20Z"
              className="box"
              ref={el => {
                els.I2 = { el: el };
              }}
            />
            <path
              d="M621.688 173.848H505.232V212.31H621.688V173.848Z"
              className="box"
              ref={el => {
                els.I3 = { el: el };
              }}
            />
            <path
              d="M621.688 20H505.232V58.462H621.688V20Z"
              className="box"
              ref={el => {
                els.I1 = { el: el };
              }}
            />
            <path
              d="M388.776 20H349.958V212.31H388.776V20Z"
              className="box"
              ref={el => {
                els.L21 = { el: el };
              }}
            />
            <path
              d="M466.413 173.848H349.958V212.31H466.413V173.848Z"
              className="box"
              ref={el => {
                els.L22 = { el: el };
              }}
            />
            <path
              d="M233.502 20H194.684V212.31H233.502V20Z"
              className="box"
              ref={el => {
                els.L11 = { el: el };
              }}
            />
            <path
              d="M311.139 173.848H194.684V212.31H311.139V173.848Z"
              className="box"
              ref={el => {
                els.L12 = { el: el };
              }}
            />
            <path
              d="M136.456 20H20V58.462H136.456V20Z"
              className="box"
              ref={el => {
                els.E1 = { el: el };
              }}
            />
            <path
              d="M58.8186 20H20V212.31H58.8186V20Z"
              className="box"
              ref={el => {
                els.E2 = { el: el };
              }}
            />
            <path
              d="M136.456 96.9241H97.6371V135.386H136.456V96.9241Z"
              className="square"
              ref={el => {
                els.Square = { el: el };
              }}
            />
            <path
              d="M136.456 173.848H20V212.31H136.456V173.848Z"
              className="box"
              ref={el => {
                els.E3 = { el: el };
              }}
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default Preloader;

import React, { Component } from "react";
import Post from "./post";
import ClassNames from "classnames";

class Instagram extends Component {
  render() {
    if (this.props.instagram.activePost === null) {
      return null;
    }
    let post = this.props.instagram.posts[this.props.instagram.activePost];
    return (
      <div
        className={ClassNames({ IG: true, "is-ded": !this.props.game.alive })}
      >
        <div className="internal">
          {post && <Post key={post.id} data={post} />}
        </div>
      </div>
    );
  }
}

export default Instagram;

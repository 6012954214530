const transforms = {
  up: [0, 1],
  right: [1, 0],
  down: [0, -1],
  left: [-1, 0]
};

export const nextPosition = (position, direction) => {
  const transform = transforms[direction];
  return position.map((axis, index) => axis + transform[index]);
};

export const wrap = (position, bounds) => {
  return position.map((axis, index) => {
    if (axis < bounds[index]) {
      return bounds[index + 2];
    } else if (axis > bounds[index + 2]) {
      return 0;
    } else {
      return axis;
    }
  });
};

export const isInBounds = (position, bounds) => {
  return (
    position[0] >= bounds[0] &&
    position[0] <= bounds[2] &&
    position[1] >= bounds[1] &&
    position[1] <= bounds[3]
  );
};

export const randomPosition = dimensions => {
  return [
    Math.floor(Math.random() * dimensions[0]),
    Math.floor(Math.random() * dimensions[1])
  ];
};

export const hasDuplicate = body => {
  return body.some((checkedSegment, checkedIndex) => {
    return body.some((segment, index) => {
      return (
        checkedIndex !== index &&
        checkedSegment[0] === segment[0] &&
        checkedSegment[1] === segment[1]
      );
    });
  });
};

import React, { Component } from "react";

class Caption extends Component {
  render() {
    return (
      <div className="Caption">
        <blockquote>{this.props.text}</blockquote>
        <a href={this.props.link} target="_blank" rel="noopener noreferrer">
          From instagram
        </a>
      </div>
    );
  }
}

export default Caption;

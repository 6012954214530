import React, { Component } from "react";

class MobileUI extends Component {
  render() {
    return (
      <div className="MobileUI">
        <button
          className="up"
          onClick={() => {
            this.props.onChange("up");
          }}
        >
          ↑
        </button>
        <br />
        <button
          onClick={() => {
            this.props.onChange("left");
          }}
          className="left"
        >
          ←
        </button>
        <span />
        <button
          onClick={() => {
            this.props.onChange("right");
          }}
          className="right"
        >
          →
        </button>
        <br />
        <button
          onClick={() => {
            this.props.onChange("down");
          }}
          className="down"
        >
          ↓
        </button>
      </div>
    );
  }
}

export default MobileUI;

import { connect } from "react-redux";
import Preloader from "./preloader";

const mapState = (state, ownProps) => ({
  ...state.instagram
});

const mapDispatch = dispatch => ({
  loaded: posts => dispatch.instagram.loaded(posts)
});

export default connect(
  mapState,
  mapDispatch
)(Preloader);

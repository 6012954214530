import React, { Component } from "react";
import Caption from "../instagram/caption";
import GameUI from "../snake/game-ui";
import ClassNames from "classnames";

class Slideshow extends Component {
  render() {
    let InstagramPost = this.props.instagram.posts[
      this.props.instagram.activePost
    ];

    return (
      <div
        className={ClassNames({
          Nav: true,
          "is-playing": this.props.game.playing,
          "is-ded": !this.props.game.alive
        })}
      >
        <div className="info">
          <div className="column">
            <h1>
              <svg
                width="26"
                height="42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25 1H1v8h24V1z"
                  stroke="#9A8479"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                  strokeLinejoin="bevel"
                />
                <path
                  d="M9 1H1v40h8V1z"
                  stroke="#9A8479"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                  strokeLinejoin="bevel"
                />
                <path d="M25 17h-8v8h8v-8z" fill="#9A8479" />
                <path
                  d="M25 33H1v8h24v-8z"
                  stroke="#9A8479"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="square"
                  strokeLinejoin="bevel"
                />
              </svg>
              Elliot <span />
              Creative
            </h1>
          </div>
          <div className="column">
            <h2>Fine Art Services</h2>
            <p>Wellington, New Zealand</p>
            <ul>
              <li>
                <a href="https://www.instagram.com/elliot_creative/">
                  @elliot_creative
                </a>
              </li>
              <li>
                <a href="mailto:tom@elliotcreative.co.nz">
                  tom@elliotcreative.co.nz
                </a>
              </li>
            </ul>
          </div>
        </div>

        <GameUI
          game={this.props.game}
          start={this.props.start}
          reset={this.props.newGame}
        />

        {InstagramPost && this.props.game.alive && (
          <Caption
            link={InstagramPost.link}
            text={InstagramPost.caption.text}
          />
        )}
      </div>
    );
  }
}

export default Slideshow;

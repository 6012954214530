import React, { Component } from "react";

class GameUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false
    };
    this.startGame = this.startGame.bind(this);
  }

  startGame(event) {
    console.log("startGame", event);
    // this.props.start();
    document.body.dispatchEvent(new CustomEvent("START_GAME"));
  }

  render() {
    if (!this.props.game.playing && this.props.game.alive) {
      return (
        <div className="GameUI">
          <div> </div>
          <div>
            <div className="desktop">Press spacebar to to begin.</div>
            <div className="mobile">
              <button onClick={this.startGame}>Tap here to start</button>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.game.playing && this.props.game.alive) {
      return (
        <div className="GameUI">
          <div>Score: {this.props.game.points}</div>
          <div className="hint desktop">(Use your arrow keys)</div>
        </div>
      );
    }

    if (!this.props.game.alive) {
      return (
        <div className="GameUI">
          <div>Score: {this.props.game.points}</div>
          <div>
            You died :(
            <br />
            <br />
            <button onClick={this.props.reset}>Start over?</button>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default GameUI;

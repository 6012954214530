import { connect } from "react-redux";
import Nav from "./nav";

const mapState = (state, ownProps) => ({
  ...state
});

const mapDispatch = dispatch => ({
  loaded: posts => dispatch.instagram.loaded(posts),
  newGame: () => dispatch.game.newGame(),
  start: () => dispatch.game.start()
});

export default connect(
  mapState,
  mapDispatch
)(Nav);

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Container from "./snake/Container";
import store from "./snake/store";
import PreContainer from "./ui/preloader-container";
import Instagram from "./instagram/container";
import Nav from "./ui/nav-container";

import "./_scss/main.scss";

ReactDOM.render(
  <Provider store={store}>
    <div className="EC">
      <Container />
      <PreContainer />
      <Instagram />

      <Nav />
    </div>
  </Provider>,
  document.getElementById("root")
);

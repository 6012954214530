import {
  nextPosition,
  wrap,
  isInBounds,
  randomPosition,
  hasDuplicate
} from "./helpers";

export const instagram = {
  state: {
    posts: [],
    activePost: null
  },
  reducers: {
    loaded(state, posts) {
      const nextState = { ...state };
      nextState.posts = posts;
      nextState.activePost = Math.floor(Math.random() * posts.length);
      return nextState;
    },
    next(state) {
      const nextState = { ...state };
      nextState.activePost = Math.floor(Math.random() * nextState.posts.length);
      return nextState;
    }
  }
};

export const game = {
  state: {
    // Body is an array of co-ordinates
    body: [[5, 5, 5]],
    direction: "up",
    speed: 8,
    dimensions: [16, 16],
    // Currently the pills are 1x1, but they could be larger
    pill: [3, 3, 3, 3],
    tile_directions: ["up"],
    moves: 0,
    points: 0,
    playing: false,
    alive: true,
    startedFromMobile: false
  },
  reducers: {
    reset(state, dimensions) {
      const nextState = { ...state };
      nextState.dimensions = dimensions;
      nextState.body = [randomPosition(dimensions)];
      const pill = randomPosition(dimensions);
      nextState.pill = [...pill, ...pill];
      return nextState;
    },
    setDirection(state, direction) {
      return { ...state, direction };
    },
    setSpeed(state, payload) {
      return { ...state, speed: payload };
    },
    play(state) {
      const nextState = { ...state };
      nextState.playing = true;
      return nextState;
    },
    pause(state) {
      const nextState = { ...state };
      nextState.playing = false;
      return nextState;
    },

    newGame(state) {
      // const nextState = { ...state };
      const newGameState = {
        body: [[5, 5, 5]],
        direction: "up",
        speed: 8,
        dimensions: [16, 16],
        // Currently the pills are 1x1, but they could be larger
        pill: [3, 3, 3, 3],
        tile_directions: ["up"],
        moves: 0,
        points: 0,
        playing: false,
        alive: true
      };
      return newGameState;
    },

    start(state) {
      console.log("models.js > start()", state);
      const nextState = { ...state };
      nextState.startedFromMobile = true;
      return nextState;
    },

    move(state, payload) {
      const nextState = { ...state };
      // Calculate where the next head position is, wrapping around the
      // dimensions of the play area
      const nextHeadPosition = wrap(
        nextPosition(state.body[0], state.direction),
        [0, 0, state.dimensions[0] - 1, state.dimensions[1] - 1]
      );
      // Add the head to the body
      nextState.body = [nextHeadPosition, ...state.body];

      // check for overlap
      if (hasDuplicate(nextState.body)) {
        console.log("should die now?");
        nextState.alive = false;
        nextState.playing = false;
      } else {
        nextState.playing = true;
      }

      // update the tile_directions
      let segs = [...state.tile_directions];
      // nextState.tile_directions.push(state.direction);
      segs.push(state.direction);
      segs = segs.slice(0 - state.body.length);
      nextState.tile_directions = segs;

      // Check of the new head position intersects the pill
      if (isInBounds(nextHeadPosition, state.pill)) {
        // Yep, add a point
        nextState.points += 1;
        // Place a new pill
        const randomX = Math.floor(Math.random() * state.dimensions[0]);
        const randomY = Math.floor(Math.random() * state.dimensions[1]);
        nextState.pill = [randomX, randomY, randomX, randomY];

        //
      } else {
        // No - so remove the tail segment
        nextState.body.splice(-1, 1);
      }
      nextState.moves += 1;
      return nextState;
    }
  },
  effects: dispatch => ({
    // Handle state changes with impure functions.
    // use async/await for async actions
    // async incrementAsync(payload, rootState) {
    //   await new Promise(resolve => setTimeout(resolve, 1000))
    //   dispatch.count.increment(payload)
    // }
  })
};

import React, { Component } from "react";
import Slideshow from "./slideshow";
import ClassNames from "classnames";

class Instagram extends Component {
  constructor(props) {
    super(props);
    this.renderMedia = this.renderMedia.bind(this);
    this.randoPosition = this.randoPosition.bind(this);
    this.mouseOver = this.mouseOver.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);
    this.state = {
      hovered: false,
      position: this.randoPosition(),
      imageLoaded: false
    };
  }
  renderMedia() {
    switch (this.props.data.type) {
      case "image":
        return (
          <img
            className={ClassNames({
              "is-loaded": this.state.imageLoaded
            })}
            onLoad={this.imageLoaded}
            src={this.props.data.images.standard_resolution.url}
            alt={this.props.data.caption.text}
          />
        );
      case "carousel":
        return (
          <Slideshow
            caption={this.props.data.caption.text}
            media={this.props.data.carousel_media}
          />
        );
      case "video":
        return (
          <video width="100%" height="100%" loop muted autoPlay>
            <source
              src={this.props.data.videos.standard_resolution.url}
              type="video/mp4"
            />
          </video>
        );
      default:
        return null;
    }
  }

  mouseOver() {
    this.setState({
      hovered: true
    });
  }

  imageLoaded() {
    this.setState({
      imageLoaded: true
    });
  }

  randoPosition() {
    let max = 16;
    let size = 4 + Math.ceil(Math.random() * (max - 12));
    let left = Math.floor(Math.random() * (max - size));
    let top = Math.floor(Math.random() * (max - size));
    return {
      top: top,
      left: left,
      width: size,
      height: size
    };
  }

  render() {
    // let post = this.props.data;
    let position = this.state.position;
    console.log(this.props.data);
    return (
      <div
        className={ClassNames({
          Post: true,
          "is-hovered": this.state.hovered
        })}
      >
        <div
          className="frame"
          style={{
            left: `${position.left * 6.25}%`,
            top: `${position.top * 6.25}%`,
            width: `${position.width * 6.25}%`,
            height: `${position.height * 6.25}%`
          }}
        >
          <a
            href={this.props.data.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.renderMedia()}
          </a>
        </div>
      </div>
    );
  }
}

export default Instagram;

import { connect } from "react-redux";
import Instagram from "./instagram";

const mapState = (state, ownProps) => ({
  ...state
});

const mapDispatch = dispatch => ({
  loaded: posts => dispatch.instagram.loaded(posts),
  pause: dispatch.game.pause()
});

export default connect(
  mapState,
  mapDispatch
)(Instagram);

import { connect } from "react-redux";
import Game from "./game";

const mapState = (state, ownProps) => ({
  ...state.game
});

const mapDispatch = dispatch => ({
  move: () => dispatch.game.move(),
  reset: dimensions => dispatch.game.reset(dimensions),
  setDirection: direction => dispatch.game.setDirection(direction),
  setSpeed: speed => dispatch.game.setSpeed(speed),
  nextPost: () => dispatch.instagram.next()
});

export default connect(
  mapState,
  mapDispatch
)(Game);
